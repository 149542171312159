<!--  -->
<template>
  <div class="bbg">
      <div class="ywlybanner">
      <img src="../assets/gywm.jpg" alt="" />
    </div>
      <div class="jjfa">
    <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>企业介绍</div>
        <div>Company profile</div>
      </div>
      <div class="xian"></div>
    </div>

    <div class="carcontent">
      <div class="right">
        <img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+datalist.fileId" alt="">
      </div>
      <div class="left">
        <div class="mytit1">
           {{datalist.introduction1}}
         </div>
        <div class="mytit1">
         {{datalist.introduction2}} </div>
      </div>
      
    </div>

  </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      datalist:''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
     this.$api
      .wxaboutdetail({aboutId:'1'})
      .then((res) => {
        this.datalist = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.ywlybanner {
  // height: 280px;
  img {
    width: 100%;
    // height: 100%;
  }
}
.top {
  width: 367px;
  padding-top: 66px;
  margin: auto;
  margin-bottom: 52px;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}
.bbg{
    //   background: #f8f8f8;
}
.jjfa {
    width: 1200px;
    margin: auto;
//   background: #f8f8f8;
    // background: url('../../assets/gywm.png');
  background-size: 100% 100%;
  min-height: 416px;
//   position: relative;
}
.carcontent {
  width: 1200px;
  margin: auto;
  margin-bottom: 66px;
}

.mytit {
  margin: 30px 0 16px;
  font-size: 20px;
  color: #333333;
  letter-spacing: 1.43px;
  line-height: 32px;
}
.mytit1 {
  font-size: 16px;
  color: #333;
  letter-spacing: 1px;
  line-height: 28px;
  margin-bottom: 30px;
}
.left {
  width: 745px;
}
.an {
  background: #237bff;
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1px;
}
.right {
  width: 408px;
  height: 260px;
  img{
    width: 100%;
    height: 100%;
  }
}
.carcontent {
  display: flex;
  justify-content: space-between;
}

</style>